// Company Modal
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

function CompanyModal() {
  const { languageData } = useSelector((state) => state.language);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <nav className="vertical-menu">
              <ul>
                <li>
                  <NavLink
                    to="/about-us/"
                    className={({ isActive }) =>
                      isActive
                        ? "active-header dropdown-menu-link font-size-20"
                        : "dropdown-menu-link font-size-20"
                    }
                  >
                    {languageData[1106]}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/blografies/"
                    className={({ isActive }) =>
                      isActive
                        ? "active-header dropdown-menu-link font-size-20"
                        : "dropdown-menu-link font-size-20"
                    }
                  >
                    Blografi
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/faq/"
                    className={({ isActive }) =>
                      isActive
                        ? "active-header dropdown-menu-link font-size-20"
                        : "dropdown-menu-link font-size-20"
                    }
                  >
                    {languageData[122]}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/online-shopping-help/"
                    className={({ isActive }) =>
                      isActive
                        ? "active-header dropdown-menu-link font-size-20"
                        : "dropdown-menu-link font-size-20"
                    }
                  >
                    {languageData[123]}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/shipping-returns/"
                    className={({ isActive }) =>
                      isActive
                        ? "active-header dropdown-menu-link font-size-20"
                        : "dropdown-menu-link font-size-20"
                    }
                  >
                    {languageData[124]}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/technical-support/"
                    className={({ isActive }) =>
                      isActive
                        ? "active-header dropdown-menu-link font-size-20"
                        : "dropdown-menu-link font-size-20"
                    }
                  >
                    {languageData[125]}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/feedback/"
                    className={({ isActive }) =>
                      isActive
                        ? "active-header dropdown-menu-link font-size-20"
                        : "dropdown-menu-link font-size-20"
                    }
                  >
                    {languageData[126]}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/career/"
                    className={({ isActive }) =>
                      isActive
                        ? "active-header dropdown-menu-link font-size-20"
                        : "dropdown-menu-link font-size-20"
                    }
                  >
                    {languageData[127]}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/contact-us/"
                    className={({ isActive }) =>
                      isActive
                        ? "active-header dropdown-menu-link font-size-20"
                        : "dropdown-menu-link font-size-20"
                    }
                  >
                    {languageData[1107]}
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyModal;
