import { createSlice } from "@reduxjs/toolkit";
import enData from "../data/translation/en.json";

const initialState = {
  languageId: "659bd867208eb8f2a4cf228c",
  languageData: enData,
  languages: [],
};

const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setLanguageId: (state, action) => {
      state.languageId = action.payload;
    },
    setLanguageData: (state, action) => {
      state.languageData = action.payload;
    },
  },
});

export const { setLanguageId, setLanguageData } = languageSlice.actions;
export default languageSlice.reducer;
