import { Icon } from "@iconify/react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ProductSearchBarList from "./ProductSearchBarList";
import "./style.css";
import { Ring } from "../Spinner";
import useSearchProduct from "../../hooks/useSearchProduct";

function useSloganAnimation(slogans, interval = 6000) {
  const [currentSlogan, setCurrentSlogan] = useState(slogans[0]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlogan((prev) => {
        const nextIndex = (slogans.indexOf(prev) + 1) % slogans.length;
        return slogans[nextIndex];
      });
    }, interval);

    return () => clearInterval(timer);
  }, [slogans, interval]);

  return currentSlogan;
}

function ProductSearchBar() {
  const { languageData } = useSelector((state) => state.language);

  const { value, setValue, isLoading, data, error, search } =
    useSearchProduct();

  const slogans = [
    "Find your perfect product!",
    "Discover the best deals!",
    "Shop with ease!",
  ];

  const placeholder = useSloganAnimation(slogans);

  useEffect(() => {
    const handleOutsideClick = () => {
      setValue("");
    };

    if (value) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }
  }, [value, setValue]);

  return (
    <div className="product-searchbar-wrapper flex-grow-1 shadow">
      <div className="input-group rounded shadow-lg-search">
        <input
          id="search-bar"
          type="text"
          className="form-control search-product"
          placeholder={placeholder}
          onChange={(e) => setValue(e.target.value)}
        />
        <div className="input-group-prepend">
          <div className="input-group-text right" style={{ height: 38 }}>
            {isLoading ? (
              <Ring size={20} thickness={2.5} />
            ) : (
              <Icon width={24} icon="tabler:search" />
            )}
          </div>
        </div>
      </div>

      {value && (error || data?.length > 0) && (
        <ProductSearchBarList
          products={data}
          search={search}
          isError={error}
          errorText={languageData[2089]}
        />
      )}
    </div>
  );
}

export default ProductSearchBar;
