import "./style.css";
import { Ellipsis, Ring as RingSpinner } from "react-css-spinners";

function Spinner({ isLarge = false }) {
  return <Ellipsis color="var(--green-dark)" size={isLarge ? 75 : 45} />;
}

export const Ring = ({
  size = 25,
  thickness = 3,
  color = "var(--green-dark)",
}) => <RingSpinner color={color} size={size} thickness={thickness} />;

export default Spinner;
