import { lazy } from "react";

const ROUTES = [
  {
    path: "/",
    component: lazy(() => import("../pages/Home")),
  },
  {
    path: "/commercial-message-text/",
    component: lazy(() => import("../pages/CommercialMessageText")),
  },
  {
    path: "/technology-areas/:id/",
    component: lazy(() => import("../pages/TechnologyArea")),
  },
  {
    path: "/terms-and-conditions/",
    component: lazy(() => import("../pages/TermsAndConditions")),
  },
  {
    path: "/privacy-policy/",
    component: lazy(() => import("../pages/PrivacyPolicy")),
  },
  {
    path: "/clarification-text/",
    component: lazy(() => import("../pages/ClarificationText")),
  },
  {
    path: "/blografies/",
    component: lazy(() => import("../pages/Blografi")),
  },
  {
    path: "/products/",
    component: lazy(() => import("../pages/Products")),
  },
  {
    path: "/products/:product_id/",
    component: lazy(() => import("../pages/ProductDetail")),
  },
  {
    path: "/contact-us/",
    component: lazy(() => import("../pages/Contact")),
  },
  {
    path: "/faq/",
    component: lazy(() => import("../pages/Support/Faq")),
  },
  {
    path: "/shipping-returns/",
    component: lazy(() => import("../pages/Support/ShippingReturns")),
  },
  {
    path: "/online-shopping-help/",
    component: lazy(() => import("../pages/Support/OnlineShoppingHelp")),
  },
  {
    path: "/technical-support/",
    component: lazy(() => import("../pages/Support/TechnicalSupport")),
  },
  {
    path: "/career/",
    component: lazy(() => import("../pages/Support/Career")),
  },
  {
    path: "/feedback/",
    component: lazy(() => import("../pages/Support/Feedback")),
  },
  {
    path: "/about-us/",
    component: lazy(() => import("../pages/AboutUs")),
  },
  {
    path: "/get-a-quotation/",
    component: lazy(() => import("../pages/GetAQuotation")),
  },
  {
    path: "/checkout/",
    component: lazy(() => import("../pages/CheckOut")),
  },
  /*   {
    path: "/inbox/",
    component: lazy(() => import("../pages/Inbox")),
  }, */
  {
    path: "/the-benefits-of-nanografi-science-club/",
    component: lazy(() =>
      import("../pages/NanografiScienceClub/BenefitsOfNanografiScienceClub")
    ),
  },
  {
    path: "/join-now/",
    component: lazy(() => import("../pages/NanografiScienceClub/JoinNow")),
  },
  {
    path: "/blografi/:id/",
    component: lazy(() => import("../pages/BlografiDetail")),
  },
  {
    path: "/orders/",
    component: lazy(() => import("../pages/Orders")),
  },
  {
    path: "/favorites/",
    component: lazy(() => import("../pages/Favorites")),
  },
  {
    path: "/login/",
    component: lazy(() => import("../pages/Login")),
  },
  {
    path: "/signup/",
    component: lazy(() => import("../pages/Signup")),
  },
  {
    path: "/payment-success/",
    component: lazy(() => import("../pages/PaymentSuccess")),
  },
  {
    path: "/payment-failure/",
    component: lazy(() => import("../pages/PaymentFailure")),
  },
  {
    path: "/recently-viewed/",
    component: lazy(() => import("../pages/RecentlyViewed")),
  },
  {
    path: "/discounted-products/",
    component: lazy(() => import("../pages/DiscountedProducts")),
  },
  {
    path: "/new-products/",
    component: lazy(() => import("../pages/NewProducts")),
  },
  {
    path: "/popular-products/",
    component: lazy(() => import("../pages/PopularProducts")),
  },
  {
    path: "/reset-password/",
    component: lazy(() => import("../pages/ResetPassword")),
  },
  {
    path: "/account-settings/",
    component: lazy(() => import("../pages/AccountSettings")),
  },
];

export default ROUTES;
